import React from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import { useViewRentingQuery, useReturnItemMutation } from "./queries.generated"
import { useDurationToDeadline } from "../../utils/Duration"
import MyText from "../../components/MyText"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import Panel from "../../components/layout/Panel"
import { Platform, Image, View } from "react-native"
import { useTranslation } from "react-i18next"
import { toString } from "lodash-es"
import { ErrorMessage, Text, TouchButton, useTheme } from "pusatec-react-native"
import { IoLocationOutline, IoTimerOutline } from "react-icons/io5"
import Loading from "../../components/Loading"
import { RentingState } from "../../doorhub-types"
import { FormatRichHtml } from "../../components/FormatRichHtml"
import { formatDateTime } from "../../utils/date"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "ViewRenting">
	route: RouteProp<MainStackParamList, "ViewRenting">
}

export default function ViewRentingScreen(props: Props) {
	const { route, navigation } = props
	const { rentingId } = route.params
	const { t } = useTranslation()
	const { colors } = useTheme()

	const { data, loading, error, refetch } = useViewRentingQuery({
		variables: { rentingId },
	})
	const renting = data?.renting
	const item = renting?.item

	const [returnItem, returnItemM] = useReturnItemMutation({
		onCompleted: (data) => {
			if (data.result!.success) {
				navigation.push("ReturnRenting", {
					rentingId: data.result!.renting!.id,
				})
			}
		},
	})

	const rentTimeLeft = useDurationToDeadline(
		new Date(data?.renting?.returnItemBefore ?? 0)
	)

	return (
		<>
			<MainScrollPanel onRefresh={refetch} refreshing={loading}>
				<Base>
					<View style={{ flexDirection: "row", marginBottom: 20 }}>
						<Image
							style={{
								width: 30,
								height: 30,
								alignSelf: "center",
								marginRight: 15,
							}}
							resizeMode="contain"
							source={require("../../../assets/icons/679902-ecommerce/svg/080-box-1.svg")}
						/>

						<MyText text={t("Renting")} style={{ color: "rgba(0,0,0,0.7)" }} />
					</View>

					{error && <ErrorMessage extra={error} />}

					{loading
						? <Loading />
						: <>
							<Text type="strong" text={toString(item?.name)} />
							<FormatRichHtml html={item?.description} max={200} />

							{data?.renting?.state === RentingState.NeedsPermit && (
								<Text text={t("Waiting for administrators approval to use this item.")} />
							)}

							{renting?.state === RentingState.Active &&
							<>
								<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
									<IoLocationOutline style={{ marginRight: 10 }} size={18} color="#333" />
									<Text
										text={t("Return to {{system}}, {{group}}", {
											system: renting?.locker?.lockersystem?.name,
											group: renting?.locker?.lockerGroup?.name,
										})}
									/>
								</View>
								<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
									<IoTimerOutline style={{ marginRight: 10 }} size={18} color="#333" />
									<View style={{ flex: 1 }}>
										<Text text={t("Return before {{deadline}}", { deadline: formatDateTime(renting?.returnItemBefore ?? 0) })} />

										{data && rentTimeLeft.overtime ? (
											<Text
												text={t("{{time}} overtime! Return as soon as possible!", {
													time: rentTimeLeft.duration?.format(t),
												})}
												style={{ color: colors.error }}
												bold
											/>
										) : (
											<Text
												text={t("{{time}} renting time left", {
													time: rentTimeLeft.duration?.format(t),
												})}
											/>
										)}
									</View>
								</View>
							</>}							
						</>}
				</Base>
			</MainScrollPanel>
			<Panel>
				{returnItemM.error && <ErrorMessage extra={returnItemM.error} />}

				<View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", alignContent: "flex-end" }}>
					{Platform.OS !== "android" && (
						<BackButton align="flex-end" />
					)}
					<View style={{ flexGrow: 1 }}>
						<TouchButton
							type="secondary"
							text={t("Report issue")}
							onPress={() => {
								navigation.push("ReportIssue", {
									situation: "RentingDuring",
									rentingId,
								})
							}}
						/>
						{renting?.state === RentingState.Active && (
							<TouchButton
								type="primary"
								text={t("Return now")}
								loading={returnItemM.loading}
								onPress={() => {
									// tslint:disable-next-line: no-floating-promises
									returnItem({
										variables: {
											rentingId: renting!.id,
										},
									})
								}}
							/>
						)}
						{renting?.state === RentingState.WaitsPickup && (
							<TouchButton
								type="primary"
								text={t("Pickup now")}
								onPress={() => {
									if (!renting) {
										return
									}

									navigation.push("RentItem", {
										rentingId: renting.id
									})
								}}
							/>
						)}
						
					</View>
				</View>
			</Panel>
		</>
	)
}
