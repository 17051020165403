import { useFeatureParametersQuery, useMyLockersystemPermissionQuery } from "./queries.generated"

export function useLockersystemFeatures (props: {
    systemId?: string
}){
	const {data} = useFeatureParametersQuery({
		variables: {
			lockersystemId: props.systemId!,
		},
		skip: !props.systemId
	})

	return data?.lockersystem
}

export const useMyLockersystemPerm = (lockersystemId: string) => {
	const {data} = useMyLockersystemPermissionQuery({
		variables: {
			lockersystemId
		},
	})
	return data?.myLockersystemPermission
}