import { View } from "react-native"
import Base from "../../components/layout/Base"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import { useState } from "react"
import { useAllowLockersystemAccessMutation, useDenyLockersystemAccessMutation, useLockersystemUsersQuery } from "./queries.generated"
import { CheckBox, SearchBar } from "react-native-elements"
import { useTranslation } from "react-i18next"
import { Text } from "pusatec-react-native"
import { theme } from "../../theme"

const UsersList = (props: {
	searchWord: string
	expanded: string | null
	setExpanded: (id: string | null) => void
	lockersystemId: string
	t: (key: string) => string
	denyLockersystemAccess: any
	allowLockersystemAccess: any
}) => {
	const { searchWord, expanded, setExpanded, lockersystemId, t, denyLockersystemAccess, allowLockersystemAccess } = props

	const { data } = useLockersystemUsersQuery({
		variables: {
			query: {
				lockersystemId,
				search: searchWord || undefined
			}
		}
	})

	if (!data) {
		return <Text text={t("Loading...")} />
	}

	const users = data.lockersystemUserPermissions.filter(user => user.user?.email)
	users.sort((a, b) => {
		if (a.user?.name && b.user?.name) return a.user.name.localeCompare(b.user.name)
		return 0
	})

	return (
		<MainScrollPanel style={{ borderWidth: 0, backgroundColor: undefined }}>
			{users.map(user => {
				let borderRightColor = 'white'
				if (user.accessDenied) borderRightColor = 'red'
				return (
					<Base
						key={user.id}
						onPress={() => setExpanded(user.id === expanded ? null : user.id as string)}
						selected={user.id === expanded}
						style={{ borderRightColor, borderRightWidth: 8 }}
					>
						<Text type="strong" text={user.user?.name || user.user?.email} />
						{expanded === user.id && (
							<>
								<Text text={user.user?.email} />
								<CheckBox
									checked={user.accessDenied === true}
									title={t("Access Denied")}
									onPress={() => {
										if (!user.accessDenied) {
											denyLockersystemAccess({
												variables: {
													input: {
														userId: user.user?.id as string,
														lockersystemId: lockersystemId
													}
												}
											})
											return
										}
										allowLockersystemAccess({
											variables: {
												input: {
													userId: user.user?.id as string,
													lockersystemId: lockersystemId
												}
											}
										})
									}}
								/>
							</>
						)}
					</Base>
				)
			})}
		</MainScrollPanel>
	)
}

export const Users = (props: {
	lockersystemId: string
}) => {
	const [expanded, setExpanded] = useState<string | null>(null)
	const { t } = useTranslation()

	const [denyLockersystemAccess] = useDenyLockersystemAccessMutation()
	const [allowLockersystemAccess] = useAllowLockersystemAccessMutation()
	const [searchWord, setSearchWord] = useState("")

	return (
		<View style={[{ flex: 1 }]}>
			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}
				value={searchWord}
				onChangeText={text => setSearchWord(text)}
			/>
			<UsersList
				searchWord={searchWord}
				expanded={expanded}
				setExpanded={setExpanded}
				lockersystemId={props.lockersystemId}
				t={t}
				denyLockersystemAccess={denyLockersystemAccess}
				allowLockersystemAccess={allowLockersystemAccess}
			/>
		</View>
	)
}