import React, { Fragment } from "react"
import { StackNavigationProp } from "@react-navigation/stack"
import { BackButton, MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import { useViewItemQuery, useRentItemMutation } from "./queries.generated"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import Duration from "../../utils/Duration"
import Panel from "../../components/layout/Panel"
import { Platform, Image, StyleSheet, View } from "react-native"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Notification, Text, TouchButton } from "pusatec-react-native"
import { IoTimerOutline, IoLocationOutline } from "react-icons/io5"
import Loading from "../../components/Loading"
import { FormatRichHtml } from "../../components/FormatRichHtml"
import {useLockersystemFeatures } from "../../components/featureParameters"
import { formatDateTime } from "../../utils/date"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "ViewItem">
	route: RouteProp<MainStackParamList, "ViewItem">
}

export default function ViewItem(props: Props) {
	const { route, navigation } = props
	const { itemId } = route.params
	const { t } = useTranslation()

	const [rentItem, rentItemM, ] = useRentItemMutation({
		onCompleted: (data) => {
			navigation.push("RentItem", {
				rentingId: data.result.renting!.id,
			})
		},
	})

	const { data, loading, error, refetch } = useViewItemQuery({
		variables: {
			itemId,
		},
	})
	const item = data?.item
	const username = data?.item?.activeRenting?.user?.name
	const rentingId = data?.item?.activeRenting?.id

	return (
		<>
			<MainScrollPanel refreshing={loading}>
				{loading
					? <Base>
						<Loading />
					</Base>
					: <Base>
						{error && <ErrorMessage extra={error} />}
						{item?.imageUrl && (
							<Image
								style={styles.image}
								source={{
									uri: item.imageUrl,
								}}
							/>
						)}
						<Text type="strong" text={item?.name} />
						<FormatRichHtml html={item?.description} max={200}  />

						{item?.needsPermit && (
							<Notification
								type="warning"
								containerStyle={{ marginTop: 20, alignSelf: "stretch" }}
								title={t("This item needs permission")}
								text={t("This item requires admin to accept the usage of this item")}
							/>
						)}

						<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
							<IoLocationOutline style={{ marginRight: 10 }} size={18} color="#333" />

							<Text text={
								item?.locker
									? `${item?.locker?.lockersystem?.name}, ${item?.locker?.lockerGroup?.name}`
									: ""
							} />
						</View>

						<View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}>
							<IoTimerOutline style={{ marginRight: 10 }} size={18} color="#333" />

							<Text
								text={`${t("Renting time")} ${new Duration(
									(item?.rentingTime ?? 0) * 1000
								).format(t)}`}
							/>
						</View>

						{(() => {
							if (item?.locker == null) {
								return <Notification
									type="warning"
									containerStyle={{ marginTop: 20, alignSelf: "stretch" }}
									title={t("Item is not available")}
									text={t("Item has been temporarily removed from the locker")}
								/>
							}

							if (item?.activeRenting != null) {
								return <><Notification
									type="warning"
									containerStyle={{ marginTop: 20, alignSelf: "stretch" }}
									title={t("Item is not available")}
									text={t("Item is currently borrowed {{releaseTime}}", {
										releaseTime: formatDateTime(item.activeRenting.returnItemBefore ?? "")
									})} /><ShowWhoBorrows  lockersystemId={data?.item?.lockersystem?.id!} username={username!} /></>
							}

							if (item?.available == false) {
								return <Notification
									type="warning"
									containerStyle={{ marginTop: 20, alignSelf: "stretch" }}
									title={t("Item is not available")}
									text={""}
								/>
							}
						})()}

					</Base>}
			</MainScrollPanel>
			<Panel>

				{rentItemM.error && (
					<ErrorMessage extra={rentItemM.error} />
				)}
				<View style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
					{Platform.OS !== "android" && (
						<BackButton />
					)}
					<TouchButton
						type="primary"
						text={t("Rent now")}
						disabled={!item?.available}
						loading={rentItemM.loading}
						onPress={() => {
							rentItem({
								variables: {
									itemId: item!.id,
								},
							})
						}}
					/>
				</View>
			</Panel>
		</>
	)
}

const styles = StyleSheet.create({
	image: { width: "100%", height: 300, resizeMode: "contain" },
})

export function ShowWhoBorrows (props: {
	lockersystemId: string,
	username: string
}){
	const { t } = useTranslation()
	const username = props.username
	const lockersystemId = props.lockersystemId
	const lockersystem = useLockersystemFeatures({systemId: lockersystemId})
	
	if (lockersystem?.showWhoBorrows == false) {
		return <Text text={""} />
	}else{
		return <Text text={t("Borrower is {{username}}", {username})} />
	}
}
