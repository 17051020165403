import React, { useEffect, useMemo } from "react"
import WizardStep from "../WizardStep"
import { WizardState } from "../use-wizard"
import { useTranslation } from "react-i18next"
import { SearchBar } from "react-native-elements"
import MainScrollPanel from "../../../components/layout/MainScrollPanel"
import Panel from "../../../components/layout/Panel"
import { useDebouncedState } from "pusatec-react-utility"
import { useSelectUserQuery } from "./queries.generated"
import { theme } from "../../../theme"
import { ErrorMessage, Text, TouchButton } from "pusatec-react-native"
import { BackIcon } from "../../../components/BackIcon"
import { useMyLockersystemPerm } from "../../../components/featureParameters"
import { View } from "react-native"
import Base from "../../../components/layout/Base"
import { set } from "lodash"

export interface UserSelection {
	id?: string | null
	name?: string | null
	email?: string | null
}

interface Props {
	wizardState: WizardState
	systemId: string
	maxSelectedUsers?: number
	user?: UserSelection | null
	onComplete(users: UserSelection[]): void
	onCancel(): void
	emailEnabled: boolean
}

export default function SelectUserStep(props: Props) {
	const { systemId, user, onComplete, onCancel, wizardState } = props
	const { t } = useTranslation()

	const [searchString, debouncedSearchString, setSearchString] = useDebouncedState(
		user ? (user.email || user.name) : "",
		300
	)
	const searchStringIncomplete = !searchString || searchString.length < 3

	const perm = useMyLockersystemPerm(systemId)
	const isAdmin = perm?.isAdmin
	const { data, loading, error, refetch } = useSelectUserQuery({
		skip: !isAdmin || searchStringIncomplete,
		variables: {
			input: {
				accessToLockersystemId: systemId,
				searchWord: debouncedSearchString,
			},
		},
	})

	const [selectedUsers, setSelectedUsers] = React.useState<UserSelection[]>([])
	const [pendingUsers, setPendingUsers] = React.useState<UserSelection[]>([])
	const users = useMemo(() => {
		if (!isAdmin) return undefined
		return data?.users?.users?.
			filter((user) => !selectedUsers.some(su	 => su.id === user.id)).
			slice(0, 10)
	}, [selectedUsers, data, isAdmin])

	useEffect(() => {
		const users = data?.users?.users
		const newUsers = !users ? pendingUsers : pendingUsers.filter((pu) => !users.some((u) => u.id === pu.id))
		if (newUsers.length) {
			setSelectedUsers((oldUsers) => [...oldUsers, ...newUsers])
			setPendingUsers((oldUsers) => oldUsers.filter((pu) => !newUsers.some((nu) => nu.id === pu.id)))
		}
	}, [data?.users?.users, pendingUsers])

	const handleAddUser = (user: UserSelection) => {
		if (!user.id) {
			setSelectedUsers([user])
			setPendingUsers([])
			return
		}
		if (user.id) setSelectedUsers(oldUsers => oldUsers.filter((u) => !u.email))
		if (props.maxSelectedUsers === 1) {
			setPendingUsers([user])
			return
		}
		if (props.maxSelectedUsers && selectedUsers.length >= props.maxSelectedUsers) return
		if (selectedUsers.some((u) => u.email === user.email)) {
			setSelectedUsers(selectedUsers.filter((u) => u.email !== user.email))
			return
		}
		if (pendingUsers.some((u) => u.email === user.email)) {
			setPendingUsers(pendingUsers.filter((u) => u.email !== user.email))
			return
		}	
		setPendingUsers([...pendingUsers, user])
	}

	const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	const emailValid = searchString ? re.test(searchString) : false

	return (
		<WizardStep title={t("")} wizardState={wizardState}>
			<div
				style={{
					fontFamily: "Nunito, Mada-Regular, sans-serif",
					fontSize: 25,
					textAlign: "center",
				}}
			>
				{t("Select receiver")}
			</div>
			<SearchBar
				lightTheme
				inputStyle={theme.Input.inputStyle}
				errorStyle={theme.Input.errorMessageStyle}
				labelStyle={theme.Input.labelStyle}
				containerStyle={theme.Input.containerStyle}
				inputContainerStyle={theme.Input.inputContainerStyle}
				searchIcon={undefined}
				clearIcon={undefined}
				cancelIcon={undefined}
				onChangeText={setSearchString}
				value={searchString}
			/>
			<MainScrollPanel onRefresh={refetch} refreshing={loading}>
				{error && <ErrorMessage extra={error} />}
				{!users?.length && emailValid && <View>
					<Base onPress={() => handleAddUser({ email: searchString })}>
						<Text
							text={t("Send delivery to email {{email}}", { email: searchString })}
							style={{ textAlign: "center", color: "#008000" }}
						/>
					</Base>
				</View>}
				{selectedUsers.map((selectedUser) => (
					<Base key={selectedUser.email} selected={true} onPress={() => {
						setSelectedUsers(selectedUsers.filter((su) => su.id !== selectedUser.id))
					}}>
						<Text text={selectedUser.name} bold />
						<Text text={selectedUser.email} />
					</Base>	
				))}
				{users && users.length > 0 ? (
					users.map((user) => {
						if (!user?.email) return null;
						const selected = pendingUsers.some((su) => su.id === user.id)
						return (
							<Base key={user.id} selected={selected} onPress={() => handleAddUser(user)}>
								<Text text={user.name} bold />
								<Text text={user.email} />
							</Base>
						);
					})
				) : null}
			</MainScrollPanel>
			<Panel style={{ flexDirection: "row", justifyContent: "space-between" }}>
				<TouchButton
					type="secondary"
					text={t("Go back")}
					icon={BackIcon}
					onPress={onCancel}
				/>
				<TouchButton
					type="primary"
					disabled={!selectedUsers.length && !pendingUsers.length}
					text={t("Next")}
					onPress={() => onComplete([...selectedUsers, ...pendingUsers])}
				/>
			</Panel>
		</WizardStep>
	)
}