import React, { useState } from "react"
import { View, StyleProp, ViewStyle } from "react-native"
import { AdminViewDocument, AdminViewQuery, useInterruptDeliveryMutation } from "./queries.generated"
import { groupBy, toString } from "lodash-es"
import MainScrollPanel from "../../components/layout/MainScrollPanel"
import Base from "../../components/layout/Base"
import { ActiveViewerDocument } from "../ActiveItems/queries.generated"
import { useTranslation } from "react-i18next"
import { ErrorMessage, Text, TouchButton, useTheme } from "pusatec-react-native"
import { BsArrowRight } from "react-icons/bs"
import { useNavigation } from "@react-navigation/core"
import { StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { FormatRichHtml } from "../../components/FormatRichHtml"
import { AdminToolbar } from "./admin_toolbar"
import { HeaderBar } from "../../components/Headerbar"
import { formatDateTime } from "../../utils/date"

interface Props {
    systemId: string
    deliveries: NonNullable<AdminViewQuery["lockersystem"]>["activeDeliveries"]["deliveries"]
    onRefetch: () => void
    loading: boolean
    style?: StyleProp<ViewStyle>
}

export function ManageDeliveries(props: Props) {
	const { systemId, deliveries, loading, onRefetch, style } = props
	const navigation = useNavigation<StackNavigationProp<MainStackParamList, "AdminNavigation">>()
	const { t } = useTranslation()
	const { colors } = useTheme()

	const deliveriesGroups = groupBy(deliveries, i => i.locker?.lockerGroup?.name ?? t("Unassigned group"))

	const [selectedDeliveryId, setSelectedDeliveryId] = useState<string | null>(null)

	const [interruptDelivery, interruptDeliveryM] = useInterruptDeliveryMutation({
		onCompleted: data => {
			navigation.push("InterruptDelivery", { deliveryId: data!.interruptDelivery.delivery!.id })
		}
	})

	return <View style={[{ flex: 1 }, style]} >
		<MainScrollPanel
			style={{ borderWidth: 0, backgroundColor: undefined }}
			onRefresh={onRefetch}
			refreshing={loading}
		>
			{Object.entries(deliveriesGroups).map(([key, deliveries]) => (
				<View key={key} style={{ marginBottom: 10 }}>
					<HeaderBar text={key}  />
					{deliveries.map(delivery => {
						const selected = delivery.id === selectedDeliveryId
						const pickupTimeRunOut = (delivery.pickupBefore && new Date(delivery.pickupBefore) < new Date())
						const whoCanPickup = delivery.whoCanPickup.length < 2 ?
							delivery.whoCanPickup :
							delivery.whoCanPickup.filter(whoCanPickup => delivery.userWhoDelivered?.id !== whoCanPickup.id)

						const whoCanPickupString = whoCanPickup.map(user => user.name || user.gmail).join(", ")

						return <Base
							key={delivery.id}
							onPress={() => setSelectedDeliveryId(delivery.id)}
							selected={delivery.id === selectedDeliveryId}
							style={{
								borderRightColor: pickupTimeRunOut ? colors.error : "rgba(0,0,0,0)",
								borderRightWidth: 8,
							}}
						>
							<View style={{ flexDirection: "row", alignItems: "center" }}>
								<Text text={toString(delivery.userWhoDelivered?.name)} bold />
								<BsArrowRight style={{ marginLeft: 5, marginRight: 5, flexShrink: 0 }} />
								<Text text={whoCanPickupString} bold />
							</View>
							<Text text={t("Locker {{name}}", { name: toString(delivery.locker?.name) })} />
							{pickupTimeRunOut
								? <Text
									text={t("Pickup time has run out")}
									style={{ color: colors.error }}
									bold
								/>
								: null}

							{selected ? <>
								<FormatRichHtml html={delivery.description} max={200} />
								{delivery.createdAt && <Text text={t("Created {{time}}", { time: formatDateTime(delivery.createdAt) })} />}
								{delivery.pickupBefore && <Text text={t("Pickup before {{time}}", { time: formatDateTime(delivery.pickupBefore) })} />}
							</> : null}
						</Base>
					})}
				</View>
			))}
		</MainScrollPanel >
		<AdminToolbar>
			{interruptDeliveryM.error && <ErrorMessage extra={interruptDeliveryM.error} />}

			{selectedDeliveryId && <TouchButton
				text={t("Interrupt delivery")}
				containerStyle={{ justifyContent: "flex-end"}}
				loading={interruptDeliveryM.loading}
				onPress={() => {
					interruptDelivery({
						variables: { deliveryId: selectedDeliveryId },
						refetchQueries: [
							{
								query: ActiveViewerDocument,
							},
							{
								query: AdminViewDocument,
								variables: { systemId }
							}
						]
					})
				}}
			/>}
		</AdminToolbar>
	</View >
}
