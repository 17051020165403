import { useState, useRef } from "react";

type Outcome = "success" | "error";
export const useOutcome = (): [Outcome | null, (result: Outcome) => void] => {
	const [outcome, setOutcome] = useState<Outcome | null>(null);
	const timerRef = useRef<number | null>(null);

	const triggerOutcome = (result: Outcome) => {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		setOutcome(result);
		timerRef.current = window.setTimeout(() => {
			setOutcome(null);
			timerRef.current = null;
		}, 3000);
	};

	return [outcome, triggerOutcome];
};