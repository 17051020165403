import React, { useState, useContext } from "react"
import CreateDeliveryStep, { DeliveryInfo, } from "./create_delivery_step"
import SelectLockerStep, { LockerSelection, } from "../common-steps/SelectLockerStep"
import { SelectLockerSystemStep } from "../common-steps/SelectLockerSystemStep"
import SelectUserStep, { UserSelection } from "../common-steps/SelectUserStep"
import useWizard from "../use-wizard"
import { StackNavigationProp } from "@react-navigation/stack"
import { goBack, goToRoot, MainStackParamList } from "../../../MainNavigation"
import { RouteProp } from "@react-navigation/native"
import Loading from "../../../components/Loading"
import PlaceDeliveryStep from "./PlaceDeliveryStep"
import { AuthenticationContext } from "../../../services/authentication/authentication-context"
import { useCreateDeliveryMutation} from "./queries.generated"
import { ErrorMessage } from "pusatec-react-native"
import { useLockersystemFeatures } from "../../../components/featureParameters"
import { DateTime } from "luxon"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "CreateDelivery">
	route: RouteProp<MainStackParamList, "CreateDelivery">
}

export function CreateDeliveryWizard(props: Props) {
	const { route, navigation } = props
	const { systemId } = route.params ?? {}
	const { authentication } = useContext(AuthenticationContext)

	const wizardState = useWizard(5, systemId ? 1 : 0)
	const { activeStepIndex, nextStep, prevStep } = wizardState

	const [createDelivery, createDeliveryM] = useCreateDeliveryMutation()

	/* Wizard values */
	const [lockerSystemId, setLockerSystemId] = useState<string | null>(systemId ?? null)
	const lockersystem = useLockersystemFeatures({systemId: lockerSystemId!})
	const emailEnabled = lockersystem?.deliveryNonRegistered ?? false

	const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo | null>({
		description: "",
		pickupBefore: DateTime.local().plus({ week: 1 }).toJSDate(),
	})

	const [selectedUsers, setSelectedUsers] = useState<UserSelection[]>([])
	const [locker, setLocker] = useState<LockerSelection | null>(null)
	if (createDeliveryM.loading) return <Loading />
	if (createDeliveryM.error) return <ErrorMessage extra={createDeliveryM.error} />

	if (activeStepIndex === 0) {
		return (
			<SelectLockerSystemStep
				wizardState={wizardState}
				userId={authentication.userId}
				selectedSystemId={lockerSystemId}
				onChange={setLockerSystemId}
				onComplete={nextStep}
				onCancel={() => goBack(navigation)}
			/>
		)
	} else if (activeStepIndex === 1) {
		return (
			<CreateDeliveryStep
				wizardState={wizardState}
				initialValues={deliveryInfo ?? undefined}
				onSubmit={(values) => {
					setDeliveryInfo(values)
					nextStep()
				}}
				onCancel={prevStep}
			/>
		)
	} else if (activeStepIndex === 2) {
		return (
			<SelectUserStep
				wizardState={wizardState}
				systemId={lockerSystemId!}
				onCancel={prevStep}
				onComplete={(users) => {
					nextStep()
					setSelectedUsers(users)
				}}
				emailEnabled={emailEnabled}
			/>
		)
	} else if (activeStepIndex === 3) {
		return (
			<SelectLockerStep
				wizardState={wizardState}
				locker={locker}
				lockersystemId={lockerSystemId!}
				onCancel={prevStep}
				onChange={setLocker}
				onComplete={async () => {
					const knownUsers = selectedUsers.filter((u) => u.id != null)
					const emailUser = selectedUsers.find((u) => u.email != null)
					await createDelivery({
						variables: {
							input: {
								userIds: knownUsers.length ? knownUsers.map((u) => u.id!) : undefined,
								email: emailUser?.email ?? undefined,
								description: deliveryInfo?.description,
								pickupBefore: deliveryInfo?.pickupBefore?.toISOString() as string,
								lockerId: locker!.id,
							}
						},
					})
					nextStep()
				}}
			/>
		)
	} else if (activeStepIndex === 4) {
		const deliveryId = createDeliveryM.data!.result!.delivery!.id
		return (
			<PlaceDeliveryStep
				wizardState={wizardState}
				deliveryId={deliveryId}
				onCreateIssue={() => {
					navigation.push("ReportIssue", {
						situation: "DeliveryDeliver",
						deliveryId,
					})
				}}
				onComplete={() => goToRoot(navigation)}
				onCancel={prevStep}
			/>
		)
	}
	return null
}
