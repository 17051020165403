import { useTheme } from "pusatec-react-native"
import React from "react"
import { View } from "react-native"
import Icon from "react-native-vector-icons/AntDesign"

interface Props {
	activeStepIndex: number
	stepCount: number
}

export default function WizardProgressMarker(props: Props) {
	const { activeStepIndex, stepCount } = props
	const { colors } = useTheme()

	return (
		<View
			style={{
				marginTop: 5,
				alignSelf: "center",
				flexDirection: "row",
			}}
		>
			{[...Array(stepCount)].map((e, i) => {
				const current = i === activeStepIndex
				const previous = i < activeStepIndex

				return <Icon key={i} name={current ? "downcircle" : previous ? "checkcircle" : "minuscircle"} style={{ margin: 6 }} size={18} color={current ? colors.primary : previous ? colors.success : colors.background} />

				// return (
				// 	<View
				// 		key={i}
				// 		style={{
				// 			margin: 5,
				// 			width: 10,
				// 			height: 10,
				// 			borderRadius: 5,
				// 			backgroundColor:
				// 				i < activeStepIndex
				// 					? colors.accentLight
				// 					: i === activeStepIndex
				// 						? colors.accent
				// 						: 'rgba(0,0,0,0.3)',
				// 		}}
				// 	/>
				// )
			})}
		</View>
	)
}
