import React, { useState } from "react"
import useWizard from "../wizards/use-wizard"
import SelectUserStep, { UserSelection } from "../wizards/common-steps/SelectUserStep"
import { StackNavigationProp } from "@react-navigation/stack"
import { MainStackParamList } from "../../MainNavigation"
import { RouteProp } from "@react-navigation/core"
import { AllowRentingStep } from "../wizards/allow-renting/AllowRentingStep"
import { useLockersystemFeatures } from "../../components/featureParameters"

interface Props {
	navigation: StackNavigationProp<MainStackParamList, "AllowRenting">
	route: RouteProp<MainStackParamList, "AllowRenting">
}

export const AllowRentingWizard = (props: Props) => {
	const wizardState = useWizard(2)
	const { systemId, itemId } = props.route.params ?? {}

	const [targetUser, setTargetUser] = useState<UserSelection | null>(null)
	const lockersystem = useLockersystemFeatures({systemId: systemId!})
	const emailEnabled = lockersystem?.rentingNonRegistered ?? false

	if (wizardState.activeStepIndex === 0) {
		return <SelectUserStep
			wizardState={wizardState}
			maxSelectedUsers={1}
			systemId={systemId}
			user={targetUser}
			onCancel={() => {
				props.navigation.replace("AdminNavigation", {
					systemId: systemId
				})
			}}
			onComplete={(users) => {
				wizardState.nextStep()
				setTargetUser(users[0])
			}}
			emailEnabled={emailEnabled}
		/>
	}
	if (targetUser && wizardState.activeStepIndex === 1) {
		return <AllowRentingStep
			wizardState={wizardState}
			selectedUser={targetUser}
			itemId={itemId}
			navigation={props.navigation}
			systemId={systemId}
		/>
	}

	return null
}