export const fi = {
	// Common non domain
	Cancel: "Peruuta",
	Exit: "Poistu",
	Next: "Seuraava",
	"Go back": "Takaisin",
	Send: "Lähetä",
	Select: "Valitse",
	Edit: "Muuta",
	Set: "Aseta",
	Settings: "Asetukset",
	"Not set": "Ei asetettu",
	Name: "Nimi",
	Help: "Ohjeet",
	Description: "Kuvaus",
	"Network Error": "Verkko virhe",
	"Return to the main screen": "Palaa alkuun",
	"An error has occured": "Tapahtui virhe",
	"You have been signed out": "Sinut kirjattiin ulos",

	days: "päivää",
	hours: "tuntia",
	minutes: "minuuttia",
	seconds: "sekuntia",

	// Common terms
	Lockersystem: "Kaappijärjestelmä",
	Delivery: "Toimitus",
	Renting: "Lainaus",
	"Current renting": "Aktiivinen lainaus",
	Locker: "Lokero",
	"Pickup at": "Nouda kaapista",
	"Pickup at {{system}}, {{group}}": "Nouda kaapista {{system}}, {{group}}",
	"Return to": "Palauta kaappiin",
	"Sent on {{time}}": "Lähetetty {{time}}",
	"In locker {{locker}}": "Lokerossa {{locker}}",
	"Renting time": "Laina-aika",
	"Sent by {{user}}": "Lähettänyt {{user}}",
	"Pickup now": "Nouda nyt",
	"Rent now": "Lainaa nyt",
	"Return now": "Palauta nyt",
	"Select locker": "Valitse lokero",
	"Last pickup time": "Viimeinen noutoaika",
	"Set duration": "Aseta kesto",
	"Ready": "Valmis",
	"Empty": "Tyhjennä",
	"Update": "Päivitä",
	"User Email {{email}}": "Käyttäjän sähköposti {{email}}",

	// Info
	"Welcome!": "Tervetuloa!",
	"Not in any system info":
		"Sinun tiliäsi ei ole liitetty yhteenkään kaappijärjestelmään. Liity järjestelmään syöttämällä kaapin yhteydessä esitetty liittymiskoodi alla olevaan tekstikenttään.",
	"Signin screen info text": "Pusatecin locker.fi -monitoimikaappi on selaimen avulla käytettävä älykäs kaappijärjestelmä tavaroiden lainaukseen, toimitukseen ja säilytykseen.",

	// Active items screen
	Rentings: "Lainaukset",
	"Incoming deliveries": "Saapuvat toimitukset",
	"No rentings or deliveries": "Sinulla ei ole aktiivisia lainauksia tai toimituksia",
	"New delivery": "Uusi toimitus",
	"Rent new item": "Uusi lainaus",

	"{{time}} left to pickup": "{{time}} aikaa jäljellä",
	"Pickup time has run out":
		"Noutoaika on umpeutunut",
	"Out of time": "Aika loppui",
	"pick_time_run_out_warning": "Tämän toimituksen noutoaika on umpeutunut. Voit yrittää selvittää tilanteen ottamalla yhteyttä kaappijärjestelmän valvojaan.",
	"{{time}} renting time left": "{{time}} aikaa jäljellä",
	"{{time}} overtime! Return as soon as possible!":
		"{{time}} yliaikaa! Palauta mahdollisimman pian.",
	"NeedsPermit": "Tarvitsee luvan",
	"WaitsPickup": "Odottaa noutoa",
	"Active": "Aktiivinen",
	"WaitsReturn": "Odottaa palautusta",
	"Your deliveries": "Sinun toimituksesi",
	"inuse": "Käytössä",
	"others": "Muut",
	"My deliveries": "Toimitukseni",

	// Settings
	"Add rentable item": "Luo lainattava tavara",
	"Add new item": "Luo uusi tavara",
	"Remove rentable item": "Poista lainattava tavara",
	"Signed in as {{user}}": "Kirjautunut sisään tilillä {{user}}",
	"Sign out": "Kirjaudu ulos",
	"Sign in": "Kirjaudu sisään",
	"Join new lockersystem": "Liity järjestelmään",
	"Administrator mode": "Järjestelmävalvojan tila",
	"Succesfully joined to lockersystem {{name}}": "Järjestelmään {{name}} liityttiin onnistuneesti",
	"Join lockersystem by inputting code": "Syötä liittymiskoodi",
	"Join": "Liity",

	// View Item
	"Item is not available": "Tavara ei ole saatavilla",
	"Item has been temporarily removed from the locker": "Tavara on otettu toistaiseksi pois kaapista",
	"Item is currently borrowed {{releaseTime}}": "Tavara on tällä hetkellä lainassa. Pitäisi vapautua viimeistään {{releaseTime}}.",
	"Rentable item at": "Lainattava tavara kaapissa",
	"This item needs permission": "Tämä tavara tarvitsee lainausluvan",
	"This item requires admin to accept the usage of this item":
		"Järjestelmänvalvojan tarvitsee erikseen hyväksyä tämän tavaran käyttäminen.",

	// Renting
	"Okay": "Ok",
	"You need to wait for admin's approval to use this item. Message has been sent to admin.":
		"Sinun täytyy odottaa järjestelmänvalvojan hyväksyntää ennen kuin voit käyttää tätä tavaraa. Viesti on lähetetty järjestelmänvalvojalle.",

	// Other
	"Rentable items": "Lainattavat tavarat",
	"Select environment": "Valitse ympäristö",

	// Wizards
	"Delivery information": "Toimituksen tiedot",
	"Place delivery into locker": "Laita toimitus lokeroon",
	"Place item to locker": "Laita tavara lokeroon",
	"Select receiver": "Valitse vastaanottaja",
	"Rentable item details": "Lainattavan tavaran tiedot",
	"Remove item from locker": "Ota tavara pois lokerosta",
	"Select item to be removed": "Valitse poistettava tavara",
	"Item was created": "Tavaran luonti onnistui!",
	"You can now place the item to a locker": "Voit halutessasi asettaa sen heti lokeroon.",
	"Input receiver's name or email address": "Syötä vastaanottajan nimi tai sähköpostiosoite",
	"Place to locker": "Laita lokeroon",
	"Send delivery to email {{email}}": "Lähetä toimitus sähköpostiin {{email}}",

	// Validation errors
	"Must be in the future": "Täytyy olla tulevaisuudessa",
	"The item must have a name": "Esineellä täytyy olla nimi",
	"Renting time is too short": "Laina-aika on liian lyhyt",
	"User not found, Send to this email address {{email}} is {{valid}}": "Käyttäjää ei löytynyt, lähetä tähän sähköpostiosoitteeseen {{email}} on {{valid}}",

	// Issues
	"Report issue": "Ilmoita ongelmasta",
	"Issue report sent": "Ilmoitus lähetetty",
	"Issue has been reported": "Ilmoitus lähetetty",
	"The lockersystem admin will contact you if necessary": "Kaappijärjestelmän valvoja ottaa sinuun tarvittaessa yhteyttä ongelman ratkaisemiseksi",
	"Issue type": "Ongelman tyyppi",
	"More information about the issue": "Lisätietoa tapahtumasta",

	// Issue types:
	DeliveryPickupItemMissingFromLocker: "Toimitus puuttuu kaapista",
	DeliveryPickupDoorDidNotOpen: "Ovi ei auennut",
	DeliveryPickupDoorAcidentClosed:
		"Ovi meni vahingossa kiinni ennen tavaran ottoa kaapista",
	DeliveryPickupWrongItem: "Tavara on väärä",
	DeliveryPickupFaltyItem: "Tavara on rikki tai muuten virheellinen",
	DeliveryPickupOtherError: "Muu ongelma",

	DeliveryDeliverDoorDidNotOpen: "Ovi ei auennut",
	DeliveryDeliverDoorAcidentClosed:
		"Ovi meni vahingossa kiinni ennen tavaran asettamista",
	DeliveryDeliverOtherError: "Muu ongelma",

	RentingStartItemMissingFromLocker: "Tavara puuttuu lokerosta",
	RentingStartDoorDidNotOpen: "Ovi ei auennut",
	RentingStartDoorAcidentClosed:
		"Ovi meni vahingossa kiinni ennen tavaran ottoa kaapista",
	RentingStartWrongItem: "Tavara on väärä",
	RentingStartFaltyItem: "Tavara on virheellinen",
	RentingStartOtherError: "Muu ongelma",

	RentingDuringItemLost: "Tavara hukkui",
	RentingDuringItemBroke: "Tavara hajosi",
	RentingDuringItemNeedService: "Tavara tarvitsee huoltoa",
	RentingDuringWrongItem: "Tavara on väärä",
	RentingDuringFaltyItem: "Tavara on virheellinen",
	RentingDuringOtherError: "Muu ongelma",

	RentingReturnDoorDidNotOpen: "Ovi ei auennut",
	RentingReturnDoorAcidentClosed:
		"Ovi meni vahingossa kiinni ennen tavaran asettamista",
	RentingReturnOtherError: "Muu ongelma",

	GeneralOtherError: "Muu ongelma",

	// Code input
	"Input code into the lockers terminal":
		"Näytä QR-koodi kaapissa olevaan lukijaan, tai syötä koodi numeronäppäimillä",
	"Code has been used": "Koodi käytetty",
	"Remember to close the locker door": "Muista sulkea lokeron ovi",
	"The code has expired": "Koodi on vanhentunut",

	// Admin view
	"Items": "Tavarat",
	"Deliveries": "Toimitukset",
	"Locker {{name}}": "Lokero {{name}}",
	"Create new item": "Luo uusi tavara",
	"Create item": "Luo tavara",
	"Delete item": "Poista tavara",
	"Delete item {{name}}?": "Poista tavara {{name}}?",
	"Delete item group": "Poista tavararyhmä",
	"Delete item group {{name}}?": "Poista tavararyhmä {{name}}?",
	"Unassigned group": "Määrittelemätön ryhmä",
	"Detached items": "Irroitetut esineet",
	"Interrupt delivery": "Keskeytä toimitus",
	"In locker {{name}}": "Lokerossa {{name}}",
	"Created {{time}}": "Luotu {{time}}",
	"Pickup before {{time}}": "Nouda ennen {{time}}",
	"Interrupt renting": "Keskeytä lainaus",
	"{{time}} overtime!": "{{time}} yliaikaa!",
	"delete_item_confirmation_text": "Haluatko varmasti poistaa tämän tavaran? Tätä operaatiota ei voida peruuttaa, ja tavara poistetaan lopullisesti.",
	"delete_item_group_confirmation_text": "Haluatko varmasti poistaa tämän tavararyhmän? Tätä operaatiota ei voida peruuttaa, ja tavarat poistetaan lopullisesti.",
	"Edit item": "Muokkaa tavaraa",
	"Needs permit": "Tarvitsee luvan",
	"Rentable": "Lainattavissa",
	"Give permit": "Anna lupa",
	"Decline permit": "Hylkää lupa",
	"Permission required": "Lupa vaaditaan",
	"Itemgroup": "Tavararyhmä",
	"Waits pickup": "Odottaa noutoa",
	"Waits return": "Odottaa palautusta",
	"Allow renting": "Salli lainaus",
	"reset": "palauta",
	"save": "tallenna",
	"Messages": "Viestit",
	"Finnish Translation": "Suomenkielinen käännös",
	"English Translation": "Englanninkielinen käännös",
	"Email admin when returned": "Lähetä sähköposti kun palautettu",
	"There is nothing in that locker": "Lokerossa ei ole mitään",
	"Cancel renting": "Peruuta lainaus",
	"Access Denied": "Käyttö kielletty",
	"Users": "Käyttäjät",
	"copy": "kopioi",
	"Copying...": "Kopioidaan...",
	"Copy success": "Kopiointi onnistui",
	"Copy error": "Kopiointi epäonnistui",

	// Translation keys
	"fi": "Suomi",
	"en": "Englanti",
	"allow_renting": "Salli lainaus",
	"allow_renting_subject": "Salli lainaus sähköposti kuvaus",
	"renting_permission_request": "Lainauspyyntö",
	"renting_permission_request_subject": "Lainaus pyyntö sähköposti kuvaus",
	"rented_item_returned": "Lainattu tavara palautettu",
	"rented_item_returned_subject": "Lainattu tavara palautettu sähköposti kuvaus",
	"delivery": "Toimitus",
	"delivery_subject": "Toimitus sähköposti kuvaus",
	"delivery_interrupted": "Toimitus keskeytetty",
	"delivery_interrupted_subject": "Toimitus keskeytetty säköposti kuvaus",
	"delivery_picked_up": "Toimitus noudettu",
	"delivery_picked_up_subject": "Toimitus noudettu sähköposti kuvaus",

	// View renting
	"Return to {{system}}, {{group}}": "Palauta kaappiin {{system}}, {{group}}",
	"Return before {{deadline}}": "Palauta ennen {{deadline}}",
	"Borrower is {{username}}": "Lainaaja on {{username}}",

	// View delivery
	"Pick up before {{deadline}}": "Nouda ennen {{deadline}}",
	"Incoming delivery": "Saapuva toimitus",
	"Pickup before": "Nouda ennen",

	// Item groups
	"Item groups": "Tavararyhmät",
	"Create itemgroup": "Luo tavararyhmä",
	"Delete itemgroup": "Poista tavararyhmä",
	"No matching item groups": "Ei vastaavaa tavararyhmää",
	"Groups": "Ryhmät",
	"New item group": "Uusi tavararyhmä",

	// Translation variables
	"user_name": "Käyttäjänimi",
	"item_name": "Tavaran nimi",
	"locker_number": "Lokeron numero",
	"lockersystem_name": "Kaappijärjestelmän nimi",
	"locker_group_name": "Lokeroryhmän nimi",
	"delivery_description": "Toimituksen kuvaus",
	"pickup_before": "Nouda ennen",
	"pickup_code": "Noutokoodi",
	"user_delivered_name": "Toimittajan nimi",
	"user_delivered_email": "Toimittajan sähköposti",
}
